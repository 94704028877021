<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/customers')"
          >
            <statistic-card
              :statistic="dashboard.counters.customers"
              icon="UsersIcon"
              statistic-title="Müşteri Kartları"
              color="primary"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/interviews')"
          >
            <statistic-card
              :statistic="dashboard.counters.interviews"
              icon="MessageCircleIcon"
              statistic-title="Görüşmeler"
              color="success"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/offers')"
          >
            <statistic-card
              :statistic="dashboard.counters.offers"
              icon="FileTextIcon"
              statistic-title="Teklifler"
              color="info"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/app/services')"
          >
            <statistic-card
              :statistic="dashboard.counters.services"
              icon="SettingsIcon"
              statistic-title="Servis Formları"
              color="warning"
            />
          </div>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row
      v-if="!loading"
      class="match-height"
    >
      <b-col
        cols="12"
        md="6"
      >
        <interviews
          v-if="dashboard.interviews.length > 0"
          :data-list="dashboard.interviews"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <reminders
          v-if="dashboard.reminders.length > 0"
          :data-list="dashboard.reminders"
        />
        <services
          v-if="dashboard.services.length > 0"
          :data-list="dashboard.services"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BRow, BOverlay,
} from 'bootstrap-vue'
import StatisticCard from '@/views/Dashboard/components/StatisticCard.vue'
import Interviews from '@/views/Dashboard/components/Interviews.vue'
import Reminders from '@/views/Dashboard/components/Reminders.vue'
import Services from '@/views/Dashboard/components/Services.vue'

export default {
  name: 'Users',
  components: {
    BOverlay,
    BRow,
    BCol,
    StatisticCard,
    Interviews,
    Reminders,
    Services,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    dashboard() {
      return this.$store.getters['dashboard/dashboard']
    },
    loading() {
      return this.$store.getters['dashboard/loading']
    },
    filters() {
      return this.$store.getters['dashboard/filters']
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        if (val.sdate || val.edate) {
          this.getDashboard()
        }
      },
    },
  },
  created() {
    this.getDashboard()
  },
  methods: {
    getDashboard() {
      this.$store.dispatch('dashboard/getData', this.filters)
    },
  },
}
</script>
